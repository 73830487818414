import { getCookie } from 'helpers/cookie';
import Api from 'old-store/utils/API';
import { SagaIterator } from 'redux-saga';
import { call, select } from 'redux-saga/effects';
import { ISagaAction } from 'modules/ScrShop/store/types';
import { ITrackEventAction } from 'modules/ScrShop/store/types/analytics';
import { selectCollection } from 'store/slices/collection/selectors';

export const trackEventSaga = function* (action: ISagaAction<ITrackEventAction>): SagaIterator {
  try {
    // if (process.env.REACT_APP_DISABLE_ANALYTICS?.trim() === 'true') return;

    const collection = yield select(selectCollection);
    const anonymousId = window.localStorage.getItem('anonymousId') || getCookie('anonymousId');
    const isAuthEvent = ['user-signed-in', 'user-signed-up'].includes(action.payload.name);
    // @ts-ignore
    yield call(Api.Analytics.trackEvent, {
      name: action.payload.name,
      payload: {
        ...(isAuthEvent ? action.payload.payload?.endCustomer : action.payload.payload),
        collection_id: collection._id,
        user_id: collection._user?._id,
        screen_width: window.innerWidth,
        screen_height: window.innerHeight
      },
      app: 'gallerys',
      timestamp: new Date(),
      anonymousId
    });
  } catch (e) {
    console.error('Failed to track event', e);
  }
};
