import { createAsyncThunk } from '@reduxjs/toolkit';

import { getCookie, setCookie } from 'helpers/cookie';
import { getTranslationKey } from 'helpers/texting';
import { getEndCustomer } from 'old-store/actions';
import { RootState } from 'old-store/store';
import { IImage } from 'old-store/types/gallery';
import { Api } from 'old-store/utils';
import { setExistingImages } from 'store/slices/images';
import { toggleInfoModal } from 'store/slices/modals';
import { fetchCustomerToken, getCustomerSelectionId } from 'store/slices/selection/actions';

export const commentImage = createAsyncThunk<Promise<any>, any, { state: RootState }>(
  'images/commentImage',
  async ({ imageId, comment }: { imageId: string; comment: string }, { getState, dispatch }) => {
    try {
      const { selection, collection } = getState();
      await fetchCustomerToken(collection._user);

      const customerSelectionId = await getCustomerSelectionId(selection, dispatch);

      const commentData = {
        image_id: imageId,
        selection_id: customerSelectionId,
        collection_id: window.SITE_ID,
        comment
      };

      const { result } = await Api.TempCustomer.comment(commentData);
      dispatch(getEndCustomer());

      return result;
    } catch (e) {
      console.log('e', e);
    }
  }
);

export const hideImage = createAsyncThunk<
  Promise<boolean | undefined>,
  string,
  { state: RootState }
>('images/hideImage', async (imageId, { getState, dispatch }) => {
  try {
    const { collection, images } = getState();
    let { endCustomer } = getState();

    await fetchCustomerToken(collection._user);

    if (!endCustomer._id) {
      const getAsyncEndCustomer = getEndCustomer();
      endCustomer = await getAsyncEndCustomer(dispatch, getState);
    }

    const newImages = JSON.parse(JSON.stringify(images.images));
    const currentImage = newImages.find((image: IImage) => image._id === imageId);

    const imageHidden = currentImage.hiddenBy.includes(endCustomer._id);

    if (imageHidden) {
      currentImage.hiddenBy = currentImage.hiddenBy.filter(
        (item: string) => item !== endCustomer._id
      );

      dispatch(setExistingImages(newImages));

      await Api.Images.unHideImage(imageId);
    } else {
      currentImage.hiddenBy.push(endCustomer._id);

      dispatch(setExistingImages(newImages));

      await Api.Images.hideImage(imageId);
    }

    if (!imageHidden && !getCookie(`showHideImageHelper-${collection._id}`)) {
      dispatch(
        toggleInfoModal({
          title: getTranslationKey('firstTimeModal.title'),
          text1: getTranslationKey('firstTimeModal.text')
        })
      );

      setCookie(`showHideImageHelper-${collection._id}`, '1', 30);
    }

    // set images to correct optimistic update in case errors happened
    // const fetchedImages = await Api.Images.getImages();
    // dispatch(setImages({ images: fetchedImages, signedWildCardUrl: collection.signedWildCardUrl }));

    return imageHidden;
  } catch (e) {
    console.log('e', e);
  }
});
