import { createSlice } from '@reduxjs/toolkit';
import { ICollection } from 'types/collection';
import { fetchPortfolioCollections, fetchPortfolio } from './actions';
import { IUserBrand } from '../userBrand';

interface ITitleImageData {
  imageId: string;
  identifier: string;
  originalImageName: string;
  imgsFolder: string;
}

interface IPortfolio {
  item: {
    _id: string;
    language: number;
    userBrand: IUserBrand;
    title: string;
    description: string;
    titleImage: ITitleImageData;
    signedWildCardUrl: string;
    userEmail: string;
    userDomain: string;
    cdnDomain: string;
    collections: ICollection[];
    collectionsTotalCount: number;
    customImprint: string;
    customPrivacy: string;
    customDomain: string;
    customDomainImprint: boolean | null;
    customDomainPrivacy: boolean | null;
    checkPortfolioPw: boolean;
  };
  isPortfolioLoading: boolean;
  isPortfolioCollectionsLoading: boolean;
}

const initialState: IPortfolio = {
  item: {
    _id: '',
    language: 0,
    userBrand: {
      _id: '',
      name: '',
      defaultValues: true,
      logo: null,
      logoLight: null,
      studioLogo: null,
      icon: null,
      website: '',
      email: '',
      tel: '',
      company: '',
      socials: {
        facebook: '',
        instagram: '',
        twitter: '',
        pintrest: '',
        youtube: ''
      }
    },
    title: '',
    description: '',
    titleImage: {
      imageId: '',
      identifier: '',
      originalImageName: '',
      imgsFolder: ''
    },
    signedWildCardUrl: '',
    userEmail: '',
    userDomain: '',
    cdnDomain: '',
    collections: [],
    collectionsTotalCount: 0,
    customImprint: '',
    customPrivacy: '',
    customDomain: '',
    customDomainImprint: false,
    customDomainPrivacy: false,
    checkPortfolioPw: false
  },
  isPortfolioLoading: false,
  isPortfolioCollectionsLoading: false
};

const portfolioSlice = createSlice({
  name: 'portfolio',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchPortfolio.pending, (state) => {
      state.isPortfolioLoading = true;
    });
    builder.addCase(fetchPortfolio.fulfilled, (state, { payload }) => {
      state.item = payload;

      state.isPortfolioLoading = false;
    });
    builder.addCase(fetchPortfolio.rejected, (state) => {
      state.isPortfolioLoading = false;
    });

    builder.addCase(fetchPortfolioCollections.pending, (state) => {
      state.isPortfolioCollectionsLoading = true;
    });
    builder.addCase(fetchPortfolioCollections.fulfilled, (state, { payload }) => {
      state.item.collections = payload.collections;
      state.item.collectionsTotalCount = payload.collectionsTotalCount;

      state.isPortfolioCollectionsLoading = false;
    });
    builder.addCase(fetchPortfolioCollections.rejected, (state) => {
      state.isPortfolioCollectionsLoading = false;
    });
  }
});

export const portfolioReducer = portfolioSlice.reducer;
