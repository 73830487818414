import { AnyAction, ThunkAction } from '@reduxjs/toolkit';

import axios from 'old-store/utils/axios';
import { RootState } from 'old-store/store';
import { setSharingUrl, startSharingLoad, stopSharingLoad } from 'store/slices/sharing';
import {
  checkIfAuthorizedAndLogoutIfNot,
  fetchCustomerToken
} from 'store/slices/selection/actions';
import { getTranslationKey } from 'helpers/texting';
import { toast } from 'react-toastify';
import ApiErrors from 'old-store/utils/API/APIErrors';

interface ISetArguments {
  url: string;
  type: string;
  identifier: string;
}

interface IGetArguments {
  type: string;
  identifier?: string;
  onlyPublicImages?: boolean;
  refetch?: boolean;
  callback?: (linkUrl: string, type: string, identifier: string) => void;
  showToast?: boolean;
}

export const setShareLink =
  ({ url, type, identifier }: ISetArguments): ThunkAction<void, RootState, unknown, AnyAction> =>
  (dispatch) => {
    try {
      dispatch(setSharingUrl({ type, identifier, url: encodeURIComponent(url) }));
    } catch (e) {
      console.error(e);
    }
  };

export const getShareLink =
  ({
    type,
    identifier = '',
    onlyPublicImages,
    refetch,
    callback,
    showToast
  }: IGetArguments): ThunkAction<void, RootState, unknown, AnyAction> =>
  async (dispatch, getState) => {
    try {
      const {
        sharing,
        collection,
        image: { curGalleryImage }
      } = getState();

      dispatch(startSharingLoad());

      const accessIdentifier = identifier === 'curGalleryImage' ? curGalleryImage._id : identifier;
      const accessKey = `${type}${accessIdentifier || ''}Url`;

      if (!refetch && sharing.storedUrls[accessKey]) {
        dispatch(
          setSharingUrl({ type, identifier: accessIdentifier, url: sharing.storedUrls[accessKey] })
        );

        if (callback) callback(sharing.storedUrls[accessKey], type, accessIdentifier);
        if (showToast) {
          toast.success(getTranslationKey('shareView.linkCopied'), { theme: 'colored' });
        }

        return;
      }

      await fetchCustomerToken(collection._user);

      const res = await axios.get('/api/collection/share', {
        params: {
          type,
          identifier: accessIdentifier,
          onlyPublicImages,
          collection_id: window.SITE_ID
        }
      });
      ApiErrors.checkOnApiError(res);

      if (res.data.id) {
        const linkStr = `${window.location.protocol}//${window.location.host}/share/${res.data.id}`;
        const linkUrl = encodeURIComponent(linkStr);

        dispatch(setSharingUrl({ type, identifier: accessIdentifier, url: linkUrl }));

        if (callback) callback(linkUrl, type, accessIdentifier);
        if (showToast) {
          toast.success(getTranslationKey('shareView.linkCopied'), { theme: 'colored' });
        }
      }
    } catch (e: any) {
      checkIfAuthorizedAndLogoutIfNot(e.response.status);

      console.log(e);
    } finally {
      dispatch(stopSharingLoad());
    }
  };
