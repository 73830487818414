import { toast } from 'react-toastify';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getTranslationKey } from 'helpers/texting';
import { commentImage } from 'store/slices/images/actions';
import {
  cancelSelectionAndDeleteIfUnsaved,
  saveSelection as saveSelectionAction
} from 'store/slices/selection/actions';
import type { ISelection, IFavorite } from 'old-store/types/gallery';
import { setCookie } from 'helpers/cookie';

export interface ISelectionState {
  all: ISelection[];
  selectionStarted: boolean;
  mobileSelectionMode: boolean;
  updateSelectionState: boolean;
  editSelectionModal: any;
  likes: string[];
  currentSelection: ISelection;
}

const initialState: ISelectionState = {
  all: [],
  selectionStarted: false,
  mobileSelectionMode: false,
  updateSelectionState: false,
  editSelectionModal: {},
  likes: [],
  currentSelection: {
    __v: 0,
    _collection: '',
    _endCustomer: '',
    _id: '',
    _user: '',
    name: '',
    createdAt: '',
    updatedAt: '',
    completed: false,
    hasImagesFromHiddenGalleries: false,
    favorites: []
  }
};

const selectionSlice = createSlice({
  name: 'selection',
  initialState,
  reducers: {
    saveSelection(state, { payload }: PayloadAction<ISelection[]>) {
      state.all = payload;

      const currentSelection = payload.find((selection: ISelection) => selection.current);

      if (currentSelection) {
        state.currentSelection = currentSelection;
        const likedArray = currentSelection.favorites
          .filter((el: IFavorite) => el.like)
          .map((el: IFavorite) => el._image);

        state.selectionStarted =
          Boolean(likedArray.length) || !!currentSelection.hasImagesFromHiddenGalleries;
        setCookie('selectionStarted', String(state.selectionStarted));

        state.mobileSelectionMode = payload.some((item: ISelection) => item.current);
        state.likes = likedArray;
      }
    },
    handleLikes(state, { payload }: PayloadAction<string>) {
      state.selectionStarted = true;
      setCookie('selectionStarted', 'true');

      state.mobileSelectionMode = true;

      if (state.likes.includes(payload)) {
        state.likes = state.likes.filter((item) => item !== payload);
      } else {
        state.likes.push(payload);
      }
    },
    addSelection(state, { payload }) {
      if (payload) state.all.push(payload);
      if (payload?.current) state.currentSelection = payload;
    },
    startSelection: (state) => {
      state.selectionStarted = true;
      state.mobileSelectionMode = true;
      setCookie('selectionStarted', 'true');
    },
    finishSelection(state) {
      state.selectionStarted = false;
      setCookie('selectionStarted', 'false');

      state.mobileSelectionMode = false;
      state.updateSelectionState = false;
      state.likes = [];
      state.all = state.all.map((item) => ({ ...item, current: false }));
      state.currentSelection = initialState.currentSelection;
    },
    enableMobileSelectionMode(state) {
      state.mobileSelectionMode = true;
    },
    disableMobileSelectionMode(state) {
      state.mobileSelectionMode = false;
    },
    enableUpdateSelectionState(state) {
      state.updateSelectionState = true;
    },
    disableUpdateSelectionState(state) {
      state.updateSelectionState = false;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(saveSelectionAction.fulfilled, (state, { payload }) => {
        if (!payload?.currentSelection) {
          toast.error(getTranslationKey('errorMessage'), { theme: 'colored' });

          return;
        }

        state.selectionStarted = false;
        setCookie('selectionStarted', 'false');

        state.mobileSelectionMode = false;
        state.updateSelectionState = false;
        state.likes = [];
        state.all = state.all.map((item) => ({ ...item, current: false }));
        state.currentSelection = initialState.currentSelection;
      })
      .addCase(cancelSelectionAndDeleteIfUnsaved.fulfilled, (state) => {
        state.selectionStarted = false;
        setCookie('selectionStarted', 'false');

        state.mobileSelectionMode = false;
        state.updateSelectionState = false;
        state.likes = [];
        state.currentSelection = initialState.currentSelection;
      })
      .addCase(commentImage.fulfilled, (_, { payload }) => {
        // @ts-ignore
        if (payload._id) {
          toast.success(getTranslationKey('commentSaved'), {
            theme: 'colored',
            className: 'toast-custom-top-position'
          });
        }
      });
  }
});

export const {
  saveSelection,
  handleLikes,
  startSelection,
  finishSelection,
  enableMobileSelectionMode,
  disableMobileSelectionMode,
  enableUpdateSelectionState,
  disableUpdateSelectionState,
  addSelection
} = selectionSlice.actions;

export const selectionReducer = selectionSlice.reducer;
